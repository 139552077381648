import { useMemo, useEffect } from 'react';

import { useAxios } from 'app/services/axios';

import apiConfig from './config/api';
// import mockData from 'mockData/variable/statistics.json';

const INTERVAL_MIN = 1000;
const INTERVAL_DEFAULT = 5000;

const notifyOnError = ({ payload }) => {
  if (
    payload?.response?.data?.code === 'common:31' &&
    payload.response.data.message === '/repository'
  ) {
    return {
      title: `Repository not found!`,
      description: (
        <>
          Please check <i>token</i> and try again!
        </>
      ),
    };
  }

  return false;
};

const useLoaderVariableStatistics = ({
  token,
  sessionGroupVariableName,
  sessionGroupName,
  variableName,
  onError,
  onSuccess,
  onStart,
  onRepeat,
  interval,
  intervalType = 'placid',
}) => {
  const apiRequestConfig = useMemo(
    () => ({
      axiosInstanceName: 'api',
      axiosConfig: {
        ...apiConfig.request,
        params: {
          token,
          sgvn: sessionGroupVariableName,
          sgn: sessionGroupName,
          v: variableName,
        },
        meta: {
          notifyOnError,
        },
      },
      options: {
        interval: {
          duration: interval
            ? Math.max(INTERVAL_MIN, interval)
            : INTERVAL_DEFAULT,
          type: intervalType,
        },
        manual: true,
        transformResponse: (response) => ({
          ...response,
          // data: mockData,
        }),
      },
      onError,
      onStart,
      onRepeat,
      onSuccess,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const loaderVariableStatistics = useAxios(apiRequestConfig);

  useEffect(() => {
    loaderVariableStatistics.cancel();

    loaderVariableStatistics.updateConfig({
      axiosConfig: {
        params: {
          token,
          sgvn: sessionGroupVariableName,
          sgn: sessionGroupName,
          v: variableName,
        },
      },
    });

    if (token && sessionGroupVariableName && sessionGroupName && variableName) {
      loaderVariableStatistics.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, sessionGroupVariableName, sessionGroupName, variableName]);

  useEffect(() => {
    loaderVariableStatistics.updateConfig({
      options: {
        interval: {
          duration: interval && Math.max(INTERVAL_MIN, interval),
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interval]);

  return loaderVariableStatistics;
};

export default useLoaderVariableStatistics;
