const combineMiddlewares = (...middlewares) => (event) => {
  let result = { ...event };

  if (middlewares?.length > 0) {
    middlewares.forEach((middleware) => {
      result = middleware(result);
    });
  }

  return result;
};

export default combineMiddlewares;
