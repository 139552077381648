import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';

import { assembleTitle } from 'app/services/title';

const Start = ({ parentTitle = '' }) => {
  const title = useMemo(() => assembleTitle('', parentTitle), [parentTitle]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="l-flex__item--main l-flex l-flex--column l-flex--center-center">
        <p>Please use the navigation to select a view.</p>
      </div>
    </>
  );
};

export default Start;
