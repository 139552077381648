import { memo, useMemo, useCallback, useState } from 'react';
import { VictoryBar, VictoryChart, VictoryAxis } from 'victory';
import _ from 'lodash';
import * as d3_format from 'd3-format';
import classNames from 'classnames/bind';
import ReactResizeDetector from 'react-resize-detector';

import getRenderedStringDimensions from 'app/services/utils/getRenderedStringDimensions';
import { animation, theme } from 'app/components/Victory/config/theme';
import { theme as barTheme } from './config/theme';

const themeMerged = _.merge({}, theme, barTheme);

const _VictoryBar = memo(
  ({ data = [], direction = 'vertical', className = '' }) => {
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    const fontSize = useMemo(
      () => Math.max(10, Math.min(100, Math.pow(width * height, 0.25))),
      [height, width],
    );

    const maxYAxisValue = useMemo(
      () => Math.max(...data.map((d) => d.y || 0)),
      [data],
    );

    const yAxisLabelTickFormat = useCallback(
      (d) => {
        let dRounded = Math.round(d);

        // if (maxYAxisValue > 0 && maxYAxisValue < 10) {
        //   return Number(d).toLocaleString(undefined, {
        //     maximumFractionDigits: Math.ceil(
        //       Math.abs(Math.log10([maxYAxisValue])),
        //     ),
        //     minimumFractionDigits: Math.ceil(
        //       Math.abs(Math.log10([maxYAxisValue])),
        //     ),
        //   });
        // }

        return maxYAxisValue > 2000
          ? d3_format
              .formatLocale({
                decimal: ',',
                thousands: '.',
              })
              .formatPrefix(
                '~s',
                maxYAxisValue,
              )(dRounded)
          : dRounded.toLocaleString();
      },
      [maxYAxisValue],
    );

    const lastYAxisLabelDimension = useMemo(
      () =>
        getRenderedStringDimensions(
          yAxisLabelTickFormat(data[data.length - 1]?.y),
          fontSize,
        ),
      [data, fontSize, yAxisLabelTickFormat],
    );

    const maxXAxisLabelDimension = useMemo(
      () => ({
        width: Math.max(
          ...data.map(
            (d) => getRenderedStringDimensions(d.x, fontSize)?.width || 0,
          ),
        ),
        height: Math.max(
          ...data.map(
            (d) => getRenderedStringDimensions(d.x, fontSize)?.height || 0,
          ),
        ),
      }),
      [data, fontSize],
    );

    const lastXAxisLabelDimension = useMemo(
      () => getRenderedStringDimensions(data[data.length - 1]?.x, fontSize),
      [data, fontSize],
    );

    const maxYAxisLabelDimension = useMemo(
      () => ({
        width: Math.max(
          ...data.map(
            (d) =>
              getRenderedStringDimensions(yAxisLabelTickFormat(d.y), fontSize)
                ?.width || 0,
          ),
        ),
        height: Math.max(
          ...data.map(
            (d) =>
              getRenderedStringDimensions(yAxisLabelTickFormat(d.y), fontSize)
                ?.height || 0,
          ),
        ),
      }),
      [data, fontSize, yAxisLabelTickFormat],
    );

    const isHorizontal = useMemo(
      () => direction?.toLowerCase() === 'horizontal',
      [direction],
    );

    const padding = useMemo(
      () => ({
        left: Math.max(
          50,
          Math.min(
            width / 2,
            (isHorizontal
              ? maxXAxisLabelDimension.width
              : maxYAxisLabelDimension.width) + 40,
          ),
        ),
        right: Math.max(
          30,
          Math.min(
            width / 4,
            (isHorizontal
              ? lastYAxisLabelDimension.width
              : lastXAxisLabelDimension.width) /
              2 +
              10,
          ),
        ),
        top: Math.max(
          20,
          Math.min(
            height / 2,
            (isHorizontal
              ? lastXAxisLabelDimension.height
              : lastYAxisLabelDimension.height) /
              2 +
              10,
          ),
        ),
        bottom: Math.max(
          40,
          Math.min(
            height / 2,
            (isHorizontal
              ? maxYAxisLabelDimension.height
              : maxXAxisLabelDimension.height) + 20,
          ),
        ),
      }),
      [
        height,
        isHorizontal,
        lastXAxisLabelDimension.height,
        lastXAxisLabelDimension.width,
        lastYAxisLabelDimension.height,
        lastYAxisLabelDimension.width,
        maxXAxisLabelDimension.height,
        maxXAxisLabelDimension.width,
        maxYAxisLabelDimension.height,
        maxYAxisLabelDimension.width,
        width,
      ],
    );

    const barStyle = useMemo(
      () => ({
        labels: {
          fontSize: fontSize,
        },
      }),
      [fontSize],
    );

    const xAxisStyle = useMemo(
      () => ({
        tickLabels: {
          fontSize: fontSize,
        },
      }),
      [fontSize],
    );

    const yAxisStyle = useMemo(
      () => ({
        ...themeMerged.keyAxis,
        tickLabels: {
          fontSize: fontSize,
        },
      }),
      [fontSize],
    );

    const domain = useMemo(
      () => (maxYAxisValue > 5 ? {} : { y: [0, 5] }),
      [maxYAxisValue],
    );

    const onResize = useCallback((width, height) => {
      setWidth(width);
      setHeight(height);
    }, []);

    return (
      <ReactResizeDetector
        handleWidth
        handleHeight
        refreshMode="debounce"
        refreshRate={1000}
        refreshOptions={{
          leading: true,
          trailing: true,
        }}
        onResize={onResize}
      >
        {({ width, height, targetRef }) => (
          <div className={classNames('victory-bar', className)} ref={targetRef}>
            {width && height && (
              <svg width={width} height={height} className="victory-bar__svg">
                <VictoryChart
                  standalone={false}
                  animate={animation}
                  height={height}
                  width={width}
                  domainPadding={height / 2.5 / data.length}
                  theme={themeMerged}
                  padding={padding}
                  domain={domain}
                >
                  <VictoryAxis
                    dependentAxis
                    style={yAxisStyle}
                    tickFormat={yAxisLabelTickFormat}
                    fixLabelOverlap
                  />
                  <VictoryAxis style={xAxisStyle} fixLabelOverlap />
                  <VictoryBar
                    barWidth={height / 5 / data.length}
                    horizontal={isHorizontal}
                    data={data}
                    style={barStyle}
                  />
                </VictoryChart>
              </svg>
            )}
          </div>
        )}
      </ReactResizeDetector>
    );
  },
);

export default _VictoryBar;
