const getRenderedStringDimensions = (string, fontSize) => {
  const element = document.createElement('span');
  document.body.appendChild(element);

  if (fontSize > 0) {
    element.style.fontSize = `${fontSize}px`;
  }
  element.style.position = 'absolute';
  element.style.whiteSpace = 'no-wrap';
  element.style.visibility = 'hidden';
  element.innerHTML = string;

  const dimension = {
    width: Math.ceil(element.clientWidth),
    height: Math.ceil(element.clientHeight),
  };

  document.body.removeChild(element);

  return dimension;
};

export default getRenderedStringDimensions;
