const getUrlSearchParams = (search = window?.location.search) => {
  const urlSearchParams = new URLSearchParams(search);
  const result = {};

  for (const [key, value] of urlSearchParams.entries()) {
    result[key] = value;
  }

  return result;
};

export default getUrlSearchParams;
