import { Fragment } from 'react';
import _ from 'lodash';

import * as networkUtils from 'app/services/network';

const endpoints = [
  {
    url: `${process.env?.REACT_APP_OBJECT_API_URL || ''}/io/last`,
    method: 'GET',
    description: 'Fetch last object trigger timestamp.',
  },
  {
    url: `${process.env?.REACT_APP_OBJECT_API_URL || ''}/io/search`,
    method: 'GET',
    description: 'Fetch object trigger payloads.',
  },
  {
    url: `${process.env?.REACT_APP_API_URL || ''}/pub/var/stats`,
    method: 'GET',
    description: 'Fetch variable statistics.',
  },
];

export const augmentError = (error) => {
  if (!window.navigator.onLine || error?.message === 'Network Error') {
    return networkUtils.augmentError(error);
  }

  let result;

  if (error?.response?.data?.code) {
    switch (error.response.data.code) {
      case 'common:31':
        switch (error.response.data.message) {
          case 'totoObject':
          case '/totoObject':
            result = {
              title: 'Object not found!',
              // description: 'No object found with the specified key and secret.',
            };
            break;
          case 'repository':
          case '/repository':
            result = {
              title: 'Repository not found!',
            };
            break;
          case 'RepoVar':
          case '/RepoVar':
            result = {
              title: 'Variable not found!',
            };
            break;
          default:
            break;
        }
        break;
      case undefined:
        break;
      default:
        break;
    }
  }

  if (!result) {
    if (error?.response?.data?.message) {
      result = {
        title: error.response.statusText,
        description: error.response.data.message,
      };
    } else {
      if (error?.response?.statusText || error?.message) {
        result = {
          title: error?.response?.statusText,
          description: error?.message,
        };
      }
    }
  }

  return result;
};

export const createLogItem = (event) => {
  // if no method has been defined axios will use GET by default
  const requestMethod = event.payload?.config?.method?.toUpperCase() || 'GET';
  const requestUrl = `${event.payload?.config?.baseURL || ''}${
    event.payload?.config?.url || ''
  }`;

  const apiEndpointDef = endpoints.find(
    (endpoint) =>
      endpoint.url === requestUrl && endpoint.method === requestMethod,
  );

  const title = apiEndpointDef?.description ? (
    <Fragment>
      {(apiEndpointDef.description || '').replace(/[.!:;]*$/, '')}:{' '}
      <strong>{event.name.slice(2)}</strong>
    </Fragment>
  ) : (
    <Fragment>
      {requestMethod?.toUpperCase()} {requestUrl}{' '}
      <strong>{event.name.slice(2)}</strong>
    </Fragment>
  );

  let description;

  if (event.name === 'onError') {
    const augmentedApiError = augmentError(event.payload);

    description = (
      <Fragment>
        {augmentedApiError?.title && <div>{augmentedApiError.title}</div>}
        {augmentedApiError?.description && (
          <div>{augmentedApiError.description}</div>
        )}
      </Fragment>
    );
  }

  if (event.name === 'onSuccess') {
    if (_.isArray(event.payload?.data)) {
      description = (
        <Fragment>
          <strong>{event.payload.data.length}</strong> items
        </Fragment>
      );
    }
  }

  return _.pickBy(
    {
      title: title,
      description: description,
    },
    _.identity,
  );
};
