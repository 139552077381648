import _ from 'lodash';

const config = {
  request: {
    method: 'GET',
    url: '/io/search',
  },
  response: {
    transform: (response) => {
      let newData;

      if (response.data?.length > 0) {
        newData = {};

        newData.lastTs = Math.max(...response.data.map((d) => d.ts));

        const faultyPayloads = [];

        const payloads = _.orderBy(
          response.data
            .map((d) => {
              try {
                return JSON.parse(d.payload);
              } catch (error) {
                console.error(
                  'visualizer-web > useLoaderObjectPayloads',
                  error,
                );

                faultyPayloads.push({
                  payload: d.payload,
                  error: error,
                });

                return undefined;
              }
            })
            .filter((d) => d),
          'ts',
          'asc',
        );

        if (payloads.length > 0) {
          newData.payloads = payloads;
          newData.payloadsMerged = payloads.reduce(
            (result, currentObject) =>
              _.mergeWith({}, result, currentObject, (value, srcValue) =>
                Array.isArray(srcValue) ? srcValue : undefined,
              ),
            {},
          );
        }

        if (faultyPayloads.length > 0) {
          newData.faultyPayloads = faultyPayloads;
        }
      }

      response.data = newData;

      return response;
    },
  },
};

export default config;
