import { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Loader as RsLoader } from 'rsuite';

import ChartVariableStatistics from 'app/components/ChartVariableStatistics';
import chartTypes, {
  defaultChartType,
} from 'app/components/ChartVariableStatistics/config/chartTypes';
import chartLayout, {
  defaultChartLayout,
} from 'app/components/ChartVariableStatistics/config/chartLayout';
import FormSimple from 'app/components/FormSimple';
import useUrlSearchParams from 'app/services/url/useUrlSearchParams';
import useLoaderObject from 'app/services/loader/useLoaderObject';
import useLoaderVariableStatistics from 'app/services/loader/useLoaderVariableStatistics';
import useHeader from 'app/services/header/useHeader';
import { useLog } from 'app/services/log';
import { assembleTitle } from 'app/services/title';

const Statistics = ({ parentTitle = '' }) => {
  const history = useHistory();
  const { add: addToLog } = useLog();

  const title = useMemo(
    () => assembleTitle('Variable Statistics', parentTitle),
    [parentTitle],
  );

  const { token, key, secret } = useUrlSearchParams();

  const loaderObjectPayloadsTransformResponse = useCallback(
    (response) => {
      if (
        typeof response.data?.payloadsMerged?.chart?.type === 'string' &&
        !chartTypes.includes(
          response.data.payloadsMerged.chart.type.toLowerCase(),
        )
      ) {
        addToLog({
          type: 'error',
          title: (
            <>
              Process object trigger payloads: <strong>Error</strong>
            </>
          ),
          description: `Unknown chart type "${response.data.payloadsMerged.chart.type}". Default chart type "${defaultChartType}" will be used.`,
        });

        delete response.data.payloadsMerged.chart.type;
      }

      if (
        typeof response.data?.payloadsMerged?.chart?.layout?.direction ===
          'string' &&
        !chartLayout.directions.includes(
          response.data.payloadsMerged.chart.layout.direction.toLowerCase(),
        )
      ) {
        addToLog({
          type: 'error',
          title: (
            <>
              Process object trigger payloads: <strong>Error</strong>
            </>
          ),
          description: `Unknown chart layout direction "${response.data.payloadsMerged.chart.layout.direction}". Default chart layout direction "${defaultChartLayout.direction}" will be used.`,
        });

        delete response.data.payloadsMerged.chart.layout.direction;
      }

      if (response.data?.payloadsMerged?.reloadApp) {
        delete response.data.payloadsMerged.reloadApp;

        window.location.reload();
      }

      return response;
    },
    [addToLog],
  );

  const { loaderObjectPayloads, loaderObjectLastTs } = useLoaderObject({
    key,
    secret,
    loaderObjectPayloadsConfig: {
      transformResponse: loaderObjectPayloadsTransformResponse,
    },
  });
  const loaderVariableStatistics = useLoaderVariableStatistics({
    token,
    sessionGroupVariableName:
      loaderObjectPayloads?.data?.payload?.sessionGroupVariableName,
    sessionGroupName: loaderObjectPayloads?.data?.payload?.sessionGroupName,
    variableName: loaderObjectPayloads?.data?.payload?.variableName,
    manual: true,
    interval:
      loaderObjectPayloads.data?.payload?.chart?.updateInterval?.duration,
  });

  const hasLoaderVariableStatisticsCrucialError = useMemo(
    () =>
      loaderVariableStatistics.hasError &&
      loaderVariableStatistics?.state?.error?.response?.data?.code ===
        'common:31' &&
      loaderVariableStatistics.state.error.response.data.message ===
        '/repository',
    [loaderVariableStatistics],
  );

  const isFormShown = useMemo(
    () =>
      !key ||
      !secret ||
      !token ||
      loaderObjectLastTs.hasError ||
      loaderObjectPayloads.hasError ||
      hasLoaderVariableStatisticsCrucialError,
    [
      hasLoaderVariableStatisticsCrucialError,
      key,
      loaderObjectLastTs.hasError,
      loaderObjectPayloads.hasError,
      secret,
      token,
    ],
  );

  useHeader({ isShown: isFormShown });

  const formItems = useMemo(
    () => [
      {
        name: 'key',
        title: 'Key',
        value: key || '',
        isRequired: true,
      },
      {
        name: 'secret',
        title: 'Secret',
        value: secret || '',
        isRequired: true,
      },
      {
        name: 'token',
        title: 'Token',
        value: token || '',
        isRequired: true,
      },
    ],
    [key, secret, token],
  );

  const onFormSubmit = useCallback(
    (formValue = {}) => {
      const formValueHasChanged =
        formValue.key !== key ||
        formValue.secret !== secret ||
        formValue.token !== token;

      const shouldRefetchManually =
        formValue.key === key || formValue.secret === secret;

      loaderObjectLastTs.reset();
      loaderObjectPayloads.reset();
      loaderVariableStatistics.reset();

      if (formValueHasChanged) {
        const newUrl = new URL(window.location);

        Object.entries(formValue).forEach(([key, value]) => {
          newUrl.searchParams.set(key, value);
        });

        history.push(newUrl.pathname + newUrl.search);
      }
      if (shouldRefetchManually) {
        loaderObjectLastTs.refetch();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, key, secret, token],
  );

  useEffect(() => {
    if (isFormShown) {
      loaderObjectLastTs.cancel();
      loaderObjectPayloads.cancel();
      loaderVariableStatistics.cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormShown]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {isFormShown ? (
        <FormSimple
          items={formItems}
          title="Please define:"
          submitCaption="start"
          onSubmit={onFormSubmit}
          isLoading={
            loaderObjectLastTs.isLoading || loaderVariableStatistics.isLoading
          }
        />
      ) : (
        <>
          {loaderObjectLastTs.isLoading ? (
            <RsLoader backdrop center size="md" speed="slow" />
          ) : (
            <>
              {loaderVariableStatistics.hasData ? (
                <ChartVariableStatistics
                  data={loaderVariableStatistics.data}
                  type={loaderObjectPayloads?.data?.payload?.chart?.type}
                  layout={loaderObjectPayloads?.data?.payload?.chart?.layout}
                  dataSettings={loaderObjectPayloads?.data?.payload?.data}
                  title={loaderObjectPayloads?.data?.payload?.chart?.title}
                  subtitle={
                    loaderObjectPayloads?.data?.payload?.chart?.subtitle
                  }
                  caption={loaderObjectPayloads?.data?.payload?.chart?.caption}
                />
              ) : (
                'Waiting for payloads...'
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default Statistics;
