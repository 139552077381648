import { NavLink } from 'react-router-dom';
import {
  Header as RsHeader,
  Navbar as RsNavbar,
  Nav as RsNav,
  Icon as RsIcon,
  Avatar as RsAvatar,
  Dropdown as RsDropdown,
} from 'rsuite';
import classNames from 'classnames';

import { Context as HeaderContext } from './Context';
import Icon from 'app/components/Icon';

import styles from './Header.module.scss';

const Header = () => {
  return (
    <HeaderContext.Consumer>
      {({ subNav, navbarHeaderExtension, isShown = true }) => (
        <>
          {isShown && (
            <RsHeader>
              <RsNavbar appearance="inverse">
                <RsNavbar.Header>
                  <NavLink to="/">
                    <RsIcon className="rs-navbar-brand logo">
                      <Icon name="Toto" />
                    </RsIcon>
                  </NavLink>
                  {navbarHeaderExtension}
                </RsNavbar.Header>
                <RsNavbar.Body>
                  <div className="l-flex l-flex--row">
                    <RsNav className="l-flex__item--main">{subNav}</RsNav>
                    <RsNav>
                      <RsDropdown
                        renderTitle={() => (
                          <RsAvatar
                            circle
                            className={classNames(styles.avatarSubtle)}
                          >
                            <RsIcon icon="home" />
                          </RsAvatar>
                        )}
                        placement="bottomEnd"
                      >
                        <RsDropdown.Item componentClass={NavLink} to="/privacy">
                          privacy policy
                        </RsDropdown.Item>
                        <RsDropdown.Item componentClass={NavLink} to="/legal">
                          legal
                        </RsDropdown.Item>
                      </RsDropdown>
                    </RsNav>
                    <RsNav>
                      <RsDropdown
                        renderTitle={() => (
                          <RsAvatar
                            circle
                            className={classNames(styles.avatarSubtle)}
                          >
                            <RsIcon icon="play-circle" />
                          </RsAvatar>
                        )}
                        placement="bottomEnd"
                      >
                        <RsDropdown.Item
                          componentClass={NavLink}
                          to="/variable/group-stats"
                        >
                          variable group statistics
                        </RsDropdown.Item>
                        <RsDropdown.Item
                          componentClass={NavLink}
                          to="/variable/stats"
                        >
                          variable statistics
                        </RsDropdown.Item>
                      </RsDropdown>
                    </RsNav>
                  </div>
                </RsNavbar.Body>
              </RsNavbar>
            </RsHeader>
          )}
        </>
      )}
    </HeaderContext.Consumer>
  );
};

export default Header;
