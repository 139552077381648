import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Switch } from 'react-router-dom';

import NotFound from 'app/components/NotFound';
import List from './GroupStatistics';
import Statistics from './Statistics';
import { assembleTitle } from 'app/services/title';

const SubDomainsByName = {
  List,
  Statistics,
};

const Main = ({ parentTitle = '', ...props }) => {
  const title = useMemo(() => assembleTitle('', parentTitle), [parentTitle]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Switch>
        <Route path="/variable/group-stats">
          <div className="l-flex__item--main l-flex l-flex--column l-flex--center-center">
            <SubDomainsByName.List parentTitle={title} {...props} />
          </div>
        </Route>
        <Route path="/variable/stats">
          <div className="l-flex__item--main l-flex l-flex--column l-flex--center-center">
            <SubDomainsByName.Statistics parentTitle={title} {...props} />
          </div>
        </Route>
        <Route>
          <div className="l-flex__item--main l-flex l-flex--column l-flex--center-center">
            <NotFound parentTitle={title} />
          </div>
        </Route>
      </Switch>
    </>
  );
};

for (let key in SubDomainsByName) {
  Main[key] = SubDomainsByName[key];
}

export default Main;
