import { Notification as RsNotification } from 'rsuite';

import capitalizeString from 'app/services/utils/capitalizeString';

const notificationTypeByEventName = {
  onStart: 'open',
  onRepeat: 'open',
  onError: 'error',
  onSuccess: 'success',
};

const createAxiosNotificationMiddleware =
  ({ duration = 10000, getData } = {}) =>
  (event) => {
    const metaNotifyOn =
      event.payload?.config?.meta?.[`notify${capitalizeString(event.name)}`];
    const metaNotifyOnEvaluated =
      metaNotifyOn === true ||
      (metaNotifyOn instanceof Function && metaNotifyOn(event));

    if (metaNotifyOnEvaluated) {
      if (getData instanceof Function) {
        let data =
          metaNotifyOnEvaluated?.title || metaNotifyOnEvaluated?.description
            ? metaNotifyOnEvaluated
            : getData(event);

        if (
          (data?.title || data?.description) &&
          RsNotification[notificationTypeByEventName[event.name]]
        ) {
          RsNotification[notificationTypeByEventName[event.name]]({
            className: !data?.description && 'rs-notification-no-description',
            duration,
            ...data,
          });
        }
      }
    }

    return event;
  };

export default createAxiosNotificationMiddleware;
