export const theme = {
  keyAxis: {
    grid: {
      stroke: '#DDD',
    },
    ticks: {
      stroke: '#000',
    },
  },
};
