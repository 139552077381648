import { memo, Fragment, useMemo, useState, useCallback } from 'react';
import { VictoryPie, VictoryChart } from 'victory';
import _ from 'lodash';
import classNames from 'classnames/bind';
import ReactResizeDetector from 'react-resize-detector';

import getRenderedStringDimensions from 'app/services/utils/getRenderedStringDimensions';
import { animation, theme } from 'app/components/Victory/config/theme';
import { theme as pieTheme } from './config/theme';

import './VictoryPie.scss';

const themeMerged = _.merge({}, theme, pieTheme);

const _VictoryPie = memo(({ data = [], className = '' }) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const fontSize = useMemo(
    () => Math.max(10, Math.min(100, Math.pow(width * height, 0.25))),
    [height, width],
  );

  const dataFiltered = useMemo(() => data.filter((d) => d.y > 0), [data]);

  const maxYAxisValue = useMemo(
    () => Math.max(...dataFiltered.map((d) => d.y || 0), 0),
    [dataFiltered],
  );

  const labelHeight = useMemo(
    () => getRenderedStringDimensions('1.0', fontSize)?.width || 0,
    [fontSize],
  );

  const labelWidth = useMemo(
    () =>
      Math.max(
        ...dataFiltered.map(
          (d) => getRenderedStringDimensions(d.x, fontSize)?.width || 0,
        ),
      ),
    [dataFiltered, fontSize],
  );

  const paddingH = useMemo(
    () => Math.max(50, Math.min(width / 3, labelWidth + 20)),
    [labelWidth, width],
  );
  const paddingV = useMemo(
    () => Math.max(50, Math.min(height / 3, labelHeight + 20)),
    [height, labelHeight],
  );

  const onResize = useCallback((width, height) => {
    setWidth(width);
    setHeight(height);
  }, []);

  return (
    <ReactResizeDetector
      handleWidth
      handleHeight
      refreshMode="debounce"
      refreshRate={1000}
      refreshOptions={{
        leading: true,
        trailing: true,
      }}
      onResize={onResize}
    >
      {({ width, height, targetRef }) => (
        <div
          className={classNames('victory-pie', className, {
            'victory-pie--no-data': !maxYAxisValue,
          })}
          ref={targetRef}
        >
          {width &&
            height &&
            (maxYAxisValue > 0 ? (
              <svg width={width} height={height} className="victory-pie__svg">
                <VictoryChart
                  standalone={false}
                  animate={animation}
                  height={height}
                  width={width}
                  domainPadding={height / 2.5 / dataFiltered.length}
                  theme={themeMerged}
                  padding={{
                    left: paddingH,
                    right: paddingH,
                    top: paddingV,
                    bottom: paddingV,
                  }}
                >
                  <VictoryPie
                    data={dataFiltered}
                    style={{
                      labels: {
                        fontSize: fontSize,
                      },
                    }}
                  />
                </VictoryChart>
              </svg>
            ) : (
              <Fragment>No data to show!</Fragment>
            ))}
        </div>
      )}
    </ReactResizeDetector>
  );
});

export default _VictoryPie;
