import { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Loader as RsLoader } from 'rsuite';

import FormSimple from 'app/components/FormSimple';
import useUrlSearchParams from 'app/services/url/useUrlSearchParams';
import useLoaderObject from 'app/services/loader/useLoaderObject';
import useLoaderVariableGroupStatistics from 'app/services/loader/useLoaderVariableGroupStatistics';
import useHeader from 'app/services/header/useHeader';
import ListVariableGroupStatistics from 'app/components/ListVariableGroupStatistics';
// import { useLog } from 'app/services/log';
import { assembleTitle } from 'app/services/title';

const GroupStatistics = ({ parentTitle = '' }) => {
  const history = useHistory();
  // const { add: addToLog } = useLog();

  const title = useMemo(
    () => assembleTitle('Variable List', parentTitle),
    [parentTitle],
  );

  const { token, key, secret } = useUrlSearchParams();

  const loaderObjectPayloadsTransformResponse = useCallback((response) => {
    if (response.data?.payloadsMerged?.reloadApp) {
      delete response.data.payloadsMerged.reloadApp;

      window.location.reload();
    }

    return response;
  }, []);

  const { loaderObjectPayloads, loaderObjectLastTs } = useLoaderObject({
    key,
    secret,
    loaderObjectPayloadsConfig: {
      transformResponse: loaderObjectPayloadsTransformResponse,
    },
  });
  const loaderVariableGroupStatistics = useLoaderVariableGroupStatistics({
    token,
    sessionGroupVariableName:
      loaderObjectPayloads?.data?.payload?.sessionGroupVariableName,
    sessionGroupName: loaderObjectPayloads?.data?.payload?.sessionGroupName,
    variableNames: loaderObjectPayloads?.data?.payload?.variableNames,
    manual: true,
    interval:
      loaderObjectPayloads.data?.payload?.list?.updateInterval?.duration,
  });

  const hasLoaderVariableGroupStatisticsCrucialError = useMemo(
    () =>
      loaderVariableGroupStatistics.hasError &&
      loaderVariableGroupStatistics?.state?.error?.response?.data?.code ===
        'common:31' &&
      loaderVariableGroupStatistics.state.error.response.data.message ===
        '/repository',
    [loaderVariableGroupStatistics],
  );

  const isFormShown = useMemo(
    () =>
      !key ||
      !secret ||
      !token ||
      loaderObjectLastTs.hasError ||
      loaderObjectPayloads.hasError ||
      hasLoaderVariableGroupStatisticsCrucialError,
    [
      hasLoaderVariableGroupStatisticsCrucialError,
      key,
      loaderObjectLastTs.hasError,
      loaderObjectPayloads.hasError,
      secret,
      token,
    ],
  );

  useHeader({ isShown: isFormShown });

  const formItems = useMemo(
    () => [
      {
        name: 'key',
        title: 'Key',
        value: key || '',
        isRequired: true,
      },
      {
        name: 'secret',
        title: 'Secret',
        value: secret || '',
        isRequired: true,
      },
      {
        name: 'token',
        title: 'Token',
        value: token || '',
        isRequired: true,
      },
    ],
    [key, secret, token],
  );

  const onFormSubmit = useCallback(
    (formValue = {}) => {
      const formValueHasChanged =
        formValue.key !== key ||
        formValue.secret !== secret ||
        formValue.token !== token;

      const shouldRefetchManually =
        formValue.key === key || formValue.secret === secret;

      loaderObjectLastTs.reset();
      loaderObjectPayloads.reset();
      loaderVariableGroupStatistics.reset();

      if (formValueHasChanged) {
        const newUrl = new URL(window.location);

        Object.entries(formValue).forEach(([key, value]) => {
          newUrl.searchParams.set(key, value);
        });

        history.push(newUrl.pathname + newUrl.search);
      }
      if (shouldRefetchManually) {
        loaderObjectLastTs.refetch();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, key, secret, token],
  );

  useEffect(() => {
    if (isFormShown) {
      loaderObjectLastTs.cancel();
      loaderObjectPayloads.cancel();
      loaderVariableGroupStatistics.cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormShown]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {isFormShown ? (
        <FormSimple
          items={formItems}
          title="Please define:"
          submitCaption="start"
          onSubmit={onFormSubmit}
          isLoading={
            loaderObjectLastTs.isLoading ||
            loaderVariableGroupStatistics.isLoading
          }
        />
      ) : (
        <>
          {loaderObjectLastTs.isLoading ? (
            <RsLoader backdrop center size="md" speed="slow" />
          ) : (
            <>
              {loaderVariableGroupStatistics.hasData ? (
                <ListVariableGroupStatistics
                  data={loaderVariableGroupStatistics.data}
                  dataSettings={loaderObjectPayloads?.data?.payload?.data}
                  pageLayout={loaderObjectPayloads?.data?.payload?.layout}
                  listLayout={loaderObjectPayloads?.data?.payload?.list?.layout}
                  variableNames={
                    loaderObjectPayloads?.data?.payload?.variableNames
                  }
                  title={loaderObjectPayloads?.data?.payload?.title}
                  subtitle={loaderObjectPayloads?.data?.payload?.subtitle}
                  caption={loaderObjectPayloads?.data?.payload?.caption}
                />
              ) : (
                'Waiting for payloads...'
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default GroupStatistics;
