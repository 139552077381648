import { Fragment, useMemo } from 'react';
import _ from 'lodash';

import useLocales from 'app/services/locale/useLocales';
import getTextFromI18nContent from 'app/services/nf/toto/getTextFromI18nContent';

import './ListVariableGroupStatistics.scss';

const fallbackLocale = 'en';

const ListVariableGroupStatistics = ({
  data = [],
  dataSettings,
  variableNames = [],
  title,
  subtitle,
  caption,
  listLayout,
  pageLayout,
}) => {
  const [primaryLocale] = useLocales({
    languageCodeOnly: true,
  });

  const variables = useMemo(
    () =>
      _.orderBy(
        data.map(({ name, content, t }) => ({
          title:
            getTextFromI18nContent(content.title, primaryLocale) ||
            getTextFromI18nContent(content.title, fallbackLocale) ||
            name,
          name,
          t,
          index: variableNames.indexOf(name),
        })),
        'index',
      ),
    [data, primaryLocale, variableNames],
  );

  const orderByIndex = useMemo(
    () =>
      variables.find((variable) => variable.name === dataSettings?.orderBy)
        ?.index,
    [dataSettings?.orderBy, variables],
  );

  const dataNormalized = useMemo(() => {
    let result = Object.entries(
      _.groupBy(
        data
          .map((d) =>
            d.values.map((value) => ({
              ...value,
              varName: d.name,
              index: variables.find((variable) => variable.name === d.name)
                ?.index,
            })),
          )
          .flat(),
        'sessionId',
      ),
    ).map(([sessionId, data]) => {
      const result = [];

      data.forEach((d) => {
        result[d.index] = d;
      });

      for (let i1 = 0; i1 < variables.length; i1++) {
        if (!result[i1]) {
          result[i1] = {
            sessionId,
            index: i1,
            varName: variables[i1].name,
            value: undefined,
          };
        }
      }

      return result;
    });

    if (orderByIndex > -1) {
      result = _.orderBy(
        result,
        (d) => _.deburr(d[orderByIndex]?.value).toLowerCase(),
        dataSettings?.orderDirection || 'asc',
      );
    }

    return result.slice(
      0,
      isNaN(dataSettings?.limit) || dataSettings?.limit === null
        ? undefined
        : dataSettings.limit,
    );
  }, [
    data,
    dataSettings?.limit,
    dataSettings?.orderDirection,
    orderByIndex,
    variables,
  ]);

  return (
    <>
      <div
        className="c-list-variable-group-statistics"
        style={{
          backgroundColor: pageLayout?.background?.color,
          color: pageLayout?.text?.color,
          fontSize: pageLayout?.text?.size > 0 && `${pageLayout?.text?.size}em`,
        }}
      >
        {(title || subtitle) && (
          <div className="c-list-variable-group-statistics__header">
            {title && (
              <h1 className="c-list-variable-group-statistics__title">
                {title}
              </h1>
            )}
            {subtitle && (
              <p className="c-list-variable-group-statistics__subtitle">
                {subtitle}
              </p>
            )}
          </div>
        )}

        <div className="c-list-variable-group-statistics__content">
          {dataNormalized.length > 0 ? (
            <table>
              {listLayout?.showHeader && (
                <thead>
                  <tr>
                    {variables.map((variable, index) => (
                      <th
                        key={`th:${variable.name}`}
                        style={{ textAlign: listLayout?.alignment?.[index] }}
                      >
                        {variable.title}
                      </th>
                    ))}
                  </tr>
                </thead>
              )}
              <tbody>
                {dataNormalized.map((row, index) => (
                  <Fragment key={index}>
                    {row && row.length > 0 && (
                      <tr key={row[0].sessionId}>
                        {row.map((cell, cellIndex) => (
                          <td
                            key={`${row[0].sessionId}:${cell.varName}`}
                            style={{
                              textAlign: listLayout?.alignment?.[cellIndex],
                            }}
                          >
                            {cell.value}
                          </td>
                        ))}
                      </tr>
                    )}
                  </Fragment>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No data to show!</p>
          )}
        </div>

        {caption && (
          <div className="c-list-variable-group-statistics__footer">
            <p className="c-list-variable-group-statistics__caption">
              {caption}
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default ListVariableGroupStatistics;
