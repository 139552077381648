import { createContext, useState, useMemo } from 'react';

export const Context = createContext({
  subNav: null,
  setSubNav: () => {},
  navbarHeaderExtension: null,
  setNavbarHeaderExtension: () => {},
  isShown: true,
  setIsShown: () => {},
});

export const Provider = ({
  subNav: initialSubNav = null,
  navbarHeaderExtension: initialNavbarHeaderExtension = null,
  isShown: initialIsShown = true,
  children,
}) => {
  const [subNav, setSubNav] = useState(initialSubNav);
  const [navbarHeaderExtension, setNavbarHeaderExtension] = useState(
    initialNavbarHeaderExtension,
  );
  const [isShown, setIsShown] = useState(initialIsShown);

  const contextValue = useMemo(
    () => ({
      subNav,
      setSubNav,
      isShown,
      navbarHeaderExtension,
      setNavbarHeaderExtension,
      setIsShown,
    }),
    [navbarHeaderExtension, subNav, isShown],
  );

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};
