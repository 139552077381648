import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import axios from 'axios';
import _ from 'lodash';

import applyMiddleware from './applyMiddleware';
import createRepetitiveAsyncCallback from 'app/services/utils/createRepetitiveAsyncCallback';

const makeConfigDefaultName = 'default';

function UseAxiosException(message) {
  this.message = message;
  this.name = 'UseAxiosException';
}

const defaultConfig = {
  options: {
    manual: false,
    hasData: (data) => data,
    hasError: (state) => state.error,
  },
};

const makeUseAxios = (
  _makeConfigs = [
    {
      name: makeConfigDefaultName,
      axiosInstance: axios.create(),
    },
  ],
) => {
  let makeConfigs = [];

  const setMakeConfigs = (_makeConfigs = []) => {
    makeConfigs = _.uniqBy(
      _.isPlainObject(_makeConfigs) ? [_makeConfigs] : _makeConfigs,
    ).map(
      (_makeConfig) => ({
        name: makeConfigDefaultName,
        ..._makeConfig,
      }),
      'name',
    );
  };

  const getMakeConfig = (makeConfigName = makeConfigDefaultName) =>
    makeConfigs.find((makeConfig) => makeConfig.name === makeConfigName);

  const runMiddleware = (makeConfigName = makeConfigDefaultName, event) => {
    const middleware = getMakeConfig(makeConfigName)?.middleware;
    let result = { ...event };

    if (middleware instanceof Function) {
      result = middleware(result);
    }

    return result;
  };

  const useAxios = (configs = []) => {
    const isMountedRef = useRef();
    const configsRef = useRef();
    const configsIsObjectRef = useRef();

    const repetitiveAsyncCallbackInstancesRef = useRef([]);
    const cancelCallbacksRef = useRef([]);

    const middlewaresToRunRef = useRef([]);

    const [, forceRunMiddleware] = useState([]);

    const [states, setStates] = useState(() => {
      const configsIsObject = _.isPlainObject(configs);
      const _configs = configsIsObject ? [configs] : configs;

      return _configs?.length > 0
        ? _configs.map((config) => ({
            isLoading: !config?.options?.manual,
            response: null,
            error: null,
          }))
        : [];
    });

    const data = useMemo(
      () => states.map((state) => state?.response?.data),
      [states],
    );

    // since we don't have access to data in our asynchronous callbacks like
    // onSuccess we need a stable referenced variable here
    const dataRef = useRef();
    dataRef.current = data;

    const updateState = useCallback((newStateToMerge = {}, index) => {
      setStates((prevState) => {
        const newState = [...prevState];

        newState[index] = {
          ...prevState[index],
          ...newStateToMerge,
        };

        return newState;
      });
    }, []);

    const updateConfig = useCallback((configToMerge = {}, index) => {
      const configsToMergeIsObject = _.isPlainObject(configToMerge);

      if (configsIsObjectRef.current) {
        if (configsToMergeIsObject) {
          _.merge(configsRef.current[0], configToMerge);

          if (configToMerge?.options?.hasOwnProperty('interval')) {
            repetitiveAsyncCallbackInstancesRef.current[0].updateConfig({
              interval: configToMerge?.options?.interval,
            });
          }
        } else {
          if (process.env.NODE_ENV !== 'production') {
            throw new UseAxiosException(
              'Configuration has been defined as an Object initially and can only be updated using an Object again.',
            );
          }
        }
      } else {
        if (!configsToMergeIsObject) {
          if (index !== undefined) {
            if (configsRef.current[index]) {
              _.merge(configsRef.current[index], configToMerge);

              if (configToMerge?.options?.hasOwnProperty('interval')) {
                repetitiveAsyncCallbackInstancesRef.current[index].updateConfig(
                  {
                    interval: configToMerge?.options?.interval,
                  },
                );
              }
            } else {
              if (process.env.NODE_ENV !== 'production') {
                throw new UseAxiosException(
                  `No configuration found at the specified index ${index}!`,
                );
              }
            }
          } else {
            configsRef.current.forEach((_config, index) => {
              _.merge(configsRef.current[index], configToMerge[index]);

              if (configToMerge?.options?.hasOwnProperty('interval')) {
                repetitiveAsyncCallbackInstancesRef.current[index].updateConfig(
                  {
                    interval: configToMerge?.options?.interval,
                  },
                );
              }
            });
          }
        } else {
          if (process.env.NODE_ENV !== 'production') {
            throw new UseAxiosException(
              'Configuration has been defined as an Array of Objects initially and can only be updated using an Object and an optional configIndex. If no configIndex is set all configuration Objects will be updated.',
            );
          }
        }
      }
    }, []);

    const refetch = useCallback(
      ({ faultyOnly, verbose = true } = {}, index) => {
        if (index !== undefined) {
          if (
            repetitiveAsyncCallbackInstancesRef.current[index]?.start instanceof
            Function
          ) {
            if (!faultyOnly || states[index].error) {
              repetitiveAsyncCallbackInstancesRef.current[index].start();
            }
          } else {
            if (verbose && process.env.NODE_ENV !== 'production') {
              throw new UseAxiosException(
                `No configuration found at the specified index ${index}!`,
              );
            }
          }
        } else {
          repetitiveAsyncCallbackInstancesRef.current.forEach(
            (repetitiveAsyncCallbackInstance, index) => {
              if (repetitiveAsyncCallbackInstance?.start instanceof Function) {
                if (!faultyOnly || states[index].error) {
                  repetitiveAsyncCallbackInstance.start();
                }
              }
            },
          );
        }
      },
      [states],
    );

    const cancel = useCallback(({ verbose = true } = {}, index) => {
      if (index !== undefined) {
        if (cancelCallbacksRef.current[index] instanceof Function) {
          cancelCallbacksRef.current[index]();
        } else {
          if (verbose && process.env.NODE_ENV !== 'production') {
            throw new UseAxiosException(
              `No configuration found at the specified index ${index}!`,
            );
          }
        }

        if (
          repetitiveAsyncCallbackInstancesRef.current[index] instanceof Function
        ) {
          repetitiveAsyncCallbackInstancesRef.current[index].stop();
        }
      } else {
        cancelCallbacksRef.current.forEach((cancelCallback) => {
          if (cancelCallback instanceof Function) {
            cancelCallback();
          }
        });

        repetitiveAsyncCallbackInstancesRef.current.forEach(
          (repetitiveAsyncCallbackInstance) => {
            repetitiveAsyncCallbackInstance.stop();
          },
        );
      }
    }, []);

    const reset = useCallback(
      (index) => {
        cancel(index);

        if (index !== undefined) {
          updateState(
            {
              isLoading: false,
              error: null,
              response: null,
            },
            index,
          );

          if (configsRef.current[index].onReset instanceof Function) {
            configsRef.current[index].onReset();
          }
        } else {
          setStates(
            configsRef.current.map(() => ({
              isLoading: false,
              response: null,
              error: null,
            })),
          );

          configsRef.current.forEach((configRef) => {
            if (configRef.onReset instanceof Function) {
              configRef.onReset();
            }
          });
        }
      },
      [cancel, updateState],
    );

    if (!configsRef.current) {
      configsIsObjectRef.current = _.isPlainObject(configs);
      configsRef.current = configsIsObjectRef.current
        ? [_.merge({}, defaultConfig, configs)]
        : configs.map((config) => _.merge({}, defaultConfig, config));

      configsRef.current.forEach((config, index) => {
        repetitiveAsyncCallbackInstancesRef.current[index] =
          createRepetitiveAsyncCallback({
            asyncCallback: () => {
              if (cancelCallbacksRef.current[index] instanceof Function) {
                cancelCallbacksRef.current[index]();
              }

              if (!configsRef.current[index]) return;

              const axiosInstance = getMakeConfig(
                configsRef.current[index].axiosInstanceName,
              )?.axiosInstance;

              if (!axiosInstance) return;

              const axiosCancelTokenSource = axios.CancelToken.source();

              cancelCallbacksRef.current[index] = axiosCancelTokenSource.cancel;

              return axiosInstance({
                ...configsRef.current[index].axiosConfig,
                cancelToken: axiosCancelTokenSource.token,
              });
            },
            interval: configsRef.current[index].options?.interval,
            onStart: () => {
              const axiosInstance = getMakeConfig(
                configsRef.current[index].axiosInstanceName,
              )?.axiosInstance;

              middlewaresToRunRef.current.push([
                configsRef.current[index].axiosInstanceName,
                {
                  name: 'onStart',
                  payload: {
                    config: {
                      ...axiosInstance.defaults,
                      ...configsRef.current[index].axiosConfig,
                    },
                  },
                },
              ]);

              forceRunMiddleware([]);

              if (configsRef.current[index].onStart instanceof Function) {
                configsRef.current[index].onStart({
                  refetch,
                  updateConfig,
                  cancel,
                  reset,
                });
              }

              updateState(
                {
                  isLoading: true,
                },
                index,
              );
            },
            onRepeat: () => {
              const axiosInstance = getMakeConfig(
                configsRef.current[index].axiosInstanceName,
              )?.axiosInstance;

              middlewaresToRunRef.current.push([
                configsRef.current[index].axiosInstanceName,
                {
                  name: 'onRepeat',
                  payload: {
                    config: {
                      ...axiosInstance.defaults,
                      ...configsRef.current[index].axiosConfig,
                    },
                  },
                },
              ]);

              forceRunMiddleware([]);

              if (configsRef.current[index].onRepeat instanceof Function) {
                configsRef.current[index].onRepeat({
                  refetch,
                  updateConfig,
                  cancel,
                  reset,
                });
              }

              updateState(
                {
                  isLoading: true,
                },
                index,
              );
            },
            onSuccess: (response) => {
              const transformedResponse =
                configsRef.current[index].options?.transformResponse instanceof
                Function
                  ? configsRef.current[index].options.transformResponse(
                      response,
                    )
                  : response;

              middlewaresToRunRef.current.push([
                configsRef.current[index].axiosInstanceName,
                {
                  name: 'onSuccess',
                  payload: transformedResponse,
                },
              ]);

              forceRunMiddleware([]);

              if (configsRef.current[index].onSuccess instanceof Function) {
                configsRef.current[index].onSuccess(transformedResponse, {
                  refetch,
                  updateConfig,
                  cancel,
                  reset,
                });
              }

              if (
                _.isEqual(dataRef.current[index], transformedResponse?.data)
              ) {
                updateState(
                  {
                    isLoading: false,
                    error: null,
                  },
                  index,
                );
              } else {
                updateState(
                  {
                    isLoading: false,
                    response: transformedResponse,
                    error: null,
                  },
                  index,
                );
              }
            },
            onError: (error) => {
              middlewaresToRunRef.current.push([
                configsRef.current[index].axiosInstanceName,
                {
                  name: 'onError',
                  payload: error,
                },
              ]);

              forceRunMiddleware([]);

              if (isMountedRef.current) {
                updateState(
                  {
                    isLoading: false,
                    error: error,
                  },
                  index,
                );
              }

              if (configsRef.current[index].onError instanceof Function) {
                configsRef.current[index].onError(error, {
                  refetch,
                  updateConfig,
                  cancel,
                  reset,
                });
              }

              if (configsRef.current[index].options?.debug) {
                if (!axios.isCancel(error)) {
                  console.error(error);
                } else {
                  console.log(
                    'Request canceled!',
                    configsRef.current[index].axiosConfig,
                  );
                }
              }
            },
          });

        if (!config?.options?.manual) {
          repetitiveAsyncCallbackInstancesRef.current[index].start();
        }
      });
    }

    const hasError = useMemo(
      () =>
        states.some(
          (state, index) =>
            configsRef.current[index]?.options?.hasError instanceof Function &&
            configsRef.current[index].options.hasError(state),
        ),
      [states],
    );

    const hasData = useMemo(
      () =>
        data.some(
          (date, index) =>
            configsRef.current[index]?.options?.hasData instanceof Function &&
            configsRef.current[index].options.hasData(date),
        ),
      [data],
    );

    const isLoading = useMemo(
      () => states.some((state) => state.isLoading),
      [states],
    );

    useEffect(() => {
      isMountedRef.current = true;
    }, []);

    useEffect(() => {
      middlewaresToRunRef.current.forEach((middlewareToRun) =>
        runMiddleware(...middlewareToRun),
      );

      middlewaresToRunRef.current = [];
    });

    useEffect(
      () => () => {
        isMountedRef.current = false;
        cancel();

        repetitiveAsyncCallbackInstancesRef.current.forEach(
          (repetitiveAsyncCallbackInstance) => {
            repetitiveAsyncCallbackInstance.stop();
          },
        );
      },
      [cancel],
    );

    return {
      state: configsIsObjectRef.current ? states?.[0] : states,
      data: configsIsObjectRef.current ? data?.[0] : data,
      hasData: hasData,
      hasError: hasError,
      isLoading: isLoading,
      refetch: refetch,
      updateConfig: updateConfig,
      cancel: cancel,
      reset: reset,
    };
  };

  setMakeConfigs(_makeConfigs);

  return Object.assign(useAxios, {
    setConfig: setMakeConfigs,
  });
};

const useAxios = makeUseAxios();
const setConfig = useAxios.setConfig;

export { setConfig, useAxios, makeUseAxios, applyMiddleware };
