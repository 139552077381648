const logTypeByEventName = {
  onError: 'error',
  onSuccess: 'success',
};

const createAxiosDebugMiddleware = ({ getData, addDataToLog } = {}) => (
  event,
) => {
  if (getData instanceof Function && addDataToLog instanceof Function) {
    let data = getData(event);

    if (data?.title || data?.description || data?.code) {
      addDataToLog({
        type: logTypeByEventName[event.name],
        ...data,
      });
    }
  }

  return event;
};

export default createAxiosDebugMiddleware;
