import { useEffect, useState } from 'react';

import useLoaderObjectLastTs from '../useLoaderObjectLastTs';
import useLoaderObjectPayloads from '../useLoaderObjectPayloads';

const LOADER_OBJECT_PAYLOADS_INTERVAL_DEFAULT = 5000;

const notifyOnError = ({ payload }) => {
  if (
    payload?.response?.data?.code === 'common:31' &&
    payload.response.data.message === 'totoObject'
  ) {
    return {
      title: `Object not found!`,
      description: (
        <>
          Please check <i>key</i> and <i>token</i> and try again!
        </>
      ),
    };
  }

  return false;
};

const useLoaderObject = ({ key, secret, loaderObjectPayloadsConfig = {} }) => {
  const [loaderObjectPayloadsInterval, setLoaderObjectPayloadsInterval] =
    useState();

  const loaderObjectLastTs = useLoaderObjectLastTs({
    key,
    secret,
    meta: {
      notifyOnError,
    },
    manual: true,
  });
  const loaderObjectPayloads = useLoaderObjectPayloads({
    key,
    secret,
    since: loaderObjectLastTs?.data?.ts,
    manual: true,
    interval:
      loaderObjectPayloadsInterval || LOADER_OBJECT_PAYLOADS_INTERVAL_DEFAULT,
    meta: {
      name: 'loaderObjectPayloads',
      notifyOnError,
    },
    onSuccess: (response) => {
      if (!isNaN(response.data?.payloadsMerged?.updateInterval?.duration)) {
        setLoaderObjectPayloadsInterval(
          response.data.payloadsMerged.updateInterval.duration,
        );
      }

      if (loaderObjectPayloadsConfig?.onSuccess instanceof Function) {
        loaderObjectPayloadsConfig.onSuccess(response);
      }
    },
    onError: (error, loaderObjectApi) => {
      if (
        error?.response?.data?.code === 'common:31' &&
        error.response.data.message === 'totoObject'
      ) {
        loaderObjectApi.cancel();
      }
    },
    transformResponse: loaderObjectPayloadsConfig?.transformResponse,
  });

  useEffect(() => {
    loaderObjectLastTs.cancel();
    loaderObjectPayloads.cancel();

    if (key && secret) {
      loaderObjectLastTs.updateConfig({
        axiosConfig: {
          params: {
            key,
            secret,
          },
        },
      });

      loaderObjectPayloads.updateConfig({
        axiosConfig: {
          params: {
            key,
            secret,
          },
        },
      });

      loaderObjectLastTs.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, secret]);

  useEffect(() => {
    if (loaderObjectLastTs?.data?.ts && !loaderObjectPayloads.isLoading) {
      loaderObjectPayloads.updateConfig({
        axiosConfig: {
          params: {
            since: loaderObjectLastTs?.data?.ts,
          },
        },
      });
      loaderObjectPayloads.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaderObjectLastTs?.data?.ts]);

  return {
    loaderObjectPayloads,
    loaderObjectLastTs,
  };
};

export default useLoaderObject;
