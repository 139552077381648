import { Fragment, useMemo } from 'react';
import _ from 'lodash';

import VictoryBar from 'app/components/VictoryBar';
import VictoryPie from 'app/components/VictoryPie';

import { defaultChartType } from './config/chartTypes';
import './ChartVariableStatistics.scss';

const chartComponentByType = {
  bar: VictoryBar,
  pie: VictoryPie,
};

const ChartVariableStatistics = ({
  data = [],
  type = defaultChartType,
  layout,
  dataSettings,
  title,
  subtitle,
  caption,
}) => {
  const Chart = useMemo(
    () =>
      chartComponentByType[type?.toLowerCase()] ||
      chartComponentByType[defaultChartType],
    [type],
  );

  const dataNormalized = useMemo(() => {
    let result = [...data];

    if (_.isArray(dataSettings?.definitionRange)) {
      result = dataSettings?.definitionRange.map(
        (definition) =>
          data.find((d) => d.value === definition) || {
            value: definition,
            count:
              dataSettings?.defaultValues?.find((d) => d.key === definition)
                ?.value || 0,
          },
      );
    }

    return result.map((d) => ({
      x: d.value,
      y: d.count || 0,
    }));
  }, [data, dataSettings?.defaultValues, dataSettings?.definitionRange]);

  const chartSettings = useMemo(
    () => ({
      direction: layout?.direction?.toLowerCase(),
    }),
    [layout?.direction],
  );

  return (
    <Fragment>
      <div className="c-chart-variable-statistics">
        {(title || subtitle) && (
          <div className="c-chart-variable-statistics__header">
            {title && (
              <h1 className="c-chart-variable-statistics__title">{title}</h1>
            )}
            {subtitle && (
              <p className="c-chart-variable-statistics__subtitle">
                {subtitle}
              </p>
            )}
          </div>
        )}

        <div className="c-chart-variable-statistics__content">
          {dataNormalized.length > 0 ? (
            <Chart
              className="c-chart-variable-statistics__victory"
              data={dataNormalized}
              {...chartSettings}
            />
          ) : (
            <p>No data to show!</p>
          )}
        </div>

        {caption && (
          <div className="c-chart-variable-statistics__footer">
            <p className="c-chart-variable-statistics__caption">{caption}</p>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default ChartVariableStatistics;
