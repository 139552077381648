import Legal from './Legal';
import PrivacyPolicy from './PrivacyPolicy';
import Start from './Start';
import Variable from './Variable';

const DomainsByName = {
  Legal,
  PrivacyPolicy,
  Start,
  Variable,
};

export default DomainsByName;
