import nfTotoLocales from './locales.json';

const getTextFromI18nContent = (i18nContent, localeIso639_1) => {
  const nfTotoLocale = nfTotoLocales.find(
    (nfTotoLocale) => nfTotoLocale['ISO 639-1'] === localeIso639_1,
  );

  if (!nfTotoLocale) return;

  return i18nContent?.find(({ lang }) => lang === nfTotoLocale['ISO 639-3'])
    ?.text;
};

export default getTextFromI18nContent;
