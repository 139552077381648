import { useMemo, useState, useEffect } from 'react';
import _ from 'lodash';

import { useAxios } from 'app/services/axios';
import { useLog } from 'app/services/log';

import apiConfig from './config/api';

const INTERVAL_MIN = 1000;

const useLoaderObjectPayloads = ({
  key,
  secret,
  meta,
  onError,
  onSuccess,
  onStart,
  onRepeat,
  since,
  interval,
  intervalType = 'placid',
  transformResponse,
  manual,
}) => {
  const [data, setData] = useState();

  const { add: addToLog } = useLog();

  const apiRequestConfig = useMemo(
    () => ({
      axiosInstanceName: 'objectApi',
      axiosConfig: {
        ...apiConfig.request,
        params: {
          key,
          secret,
          since,
        },
        meta,
        // timeout: 10000,
      },
      options: {
        interval: {
          duration: interval && Math.max(INTERVAL_MIN, interval),
          type: intervalType,
        },
        transformResponse: (response) => {
          let result = response;

          if (apiConfig.response?.transform instanceof Function) {
            result = apiConfig.response.transform(response);
          }

          if (transformResponse instanceof Function) {
            result = transformResponse(response);
          }

          return result;
        },
        manual: manual,
      },
      onError,
      onStart,
      onRepeat,
      onSuccess: (response, useAxiosInstanceActions) => {
        if (onSuccess instanceof Function) {
          onSuccess(response);
        }

        if (response.data?.faultyPayloads?.length > 0) {
          response.data.faultyPayloads.forEach((faultyPayload) => {
            addToLog({
              type: 'error',
              title: (
                <>
                  Process object trigger payloads: <strong>Error</strong>
                </>
              ),
              description: 'Invalid JSON.',
              code: faultyPayload.payload,
            });
          });
        }

        if (response.data?.lastTs) {
          useAxiosInstanceActions.updateConfig({
            axiosConfig: {
              params: {
                since: response.data.lastTs,
              },
            },
          });
        }

        if (response.data?.payloadsMerged) {
          setData((prevState) =>
            _.mergeWith(
              {},
              prevState,
              {
                payload: response.data.payloadsMerged,
                ts: response.data.lastTs,
              },
              (_value, srcValue) =>
                Array.isArray(srcValue) ? srcValue : undefined,
            ),
          );
        }
      },
      onReset: () => setData(),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const loaderObjectPayloads = useAxios(apiRequestConfig);

  useEffect(() => {
    loaderObjectPayloads.updateConfig({
      axiosConfig: {
        params: {
          key,
          secret,
        },
      },
      options: {
        interval: {
          duration: interval && Math.max(INTERVAL_MIN, interval),
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interval, secret, key]);

  return {
    ...loaderObjectPayloads,
    data,
  };
};

export default useLoaderObjectPayloads;
