import { useEffect, useMemo } from 'react';

import { useAxios } from 'app/services/axios';

import apiConfig from './config/api';

const useLoaderObjectLastTs = ({
  key,
  secret,
  meta,
  onError,
  onSuccess,
  onStart,
  manual,
}) => {
  const apiRequestConfig = useMemo(
    () => ({
      axiosInstanceName: 'objectApi',
      axiosConfig: {
        ...apiConfig.request,
        params: {
          key,
          secret,
        },
        meta,
      },
      options: {
        transformResponse: apiConfig.response?.transform,
        manual: manual,
      },
      onError,
      onSuccess,
      onStart,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const loaderObjectLastTs = useAxios(apiRequestConfig);

  useEffect(() => {
    loaderObjectLastTs.updateConfig({
      axiosConfig: {
        params: {
          key,
          secret,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, secret]);

  return loaderObjectLastTs;
};

export default useLoaderObjectLastTs;
