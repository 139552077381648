import { useEffect, useContext } from 'react';

import { Context as HeaderContext } from 'app/components/Header/Context';

const useHeader = ({ isShown = true }) => {
  const headerContext = useContext(HeaderContext);

  useEffect(
    () => {
      headerContext.setIsShown(!!isShown);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isShown],
  );

  return headerContext;
};

export default useHeader;
