import { useMemo } from 'react';
import _ from 'lodash';

// https://gist.github.com/ashour/5f169a6dd9b6293691629ee0d06cae6f#file-get-browser-locales-js
function getBrowserLocales(options = {}) {
  const defaultOptions = {
    languageCodeOnly: false,
  };

  const opt = {
    ...defaultOptions,
    ...options,
  };

  const browserLocales =
    navigator.languages === undefined
      ? [navigator.language]
      : navigator.languages;

  if (!browserLocales) {
    return undefined;
  }

  return _.uniq(
    browserLocales.map((locale) => {
      const trimmedLocale = locale.trim();

      return opt.languageCodeOnly
        ? trimmedLocale.split(/-|_/)[0]
        : trimmedLocale;
    }),
  );
}

const useLocales = (options) =>
  useMemo(() => getBrowserLocales(options), [options]);

export default useLocales;
