import { useMemo, useCallback, useRef } from 'react';
import {
  FormGroup as RsFormGroup,
  ControlLabel as RsControlLabel,
  FormControl as RsFormControl,
  ButtonToolbar as RsButtonToolbar,
  Schema as RsSchema,
} from 'rsuite';

import Button from 'app/components/Button';
import FormControlled from 'app/components/FormControlled';

const FormSimple = ({
  items = [],
  title,
  isDisabled = false,
  isLoading = false,
  submitCaption = 'send',
  onSubmit,
}) => {
  const rsFormRef = useRef();

  const onFormSubmit = useCallback(
    (event) => {
      event.preventDefault();

      if (!rsFormRef.current?.check()) return;

      const rsFormValue = rsFormRef.current?.getFormValue();

      if (rsFormValue && onSubmit instanceof Function) {
        onSubmit(rsFormValue);
      }
    },
    [onSubmit],
  );

  const rsFormModelDef = useMemo(
    () =>
      Object.fromEntries([
        ...items.map((item) => [
          item.name,
          item.isRequired
            ? RsSchema.Types.StringType().isRequired('This field is required.')
            : RsSchema.Types.StringType(),
        ]),
      ]),
    [items],
  );

  const formInitialValue = useMemo(
    () => Object.fromEntries([...items.map((item) => [item.name, item.value])]),
    [items],
  );

  return (
    <FormControlled
      rsFormModelDef={rsFormModelDef}
      initialValue={formInitialValue}
      rsFormRef={rsFormRef}
    >
      {title && <p className="rs-form-title">{title}</p>}
      {items.map((item) => (
        <RsFormGroup key={item.name}>
          <RsControlLabel>{item.title}</RsControlLabel>
          <RsFormControl name={item.name} />
        </RsFormGroup>
      ))}

      <RsFormGroup>
        <RsButtonToolbar>
          <Button
            appearance="primary"
            type="submit"
            onClick={onFormSubmit}
            isDisabled={isDisabled}
            isLoading={isLoading}
          >
            {submitCaption}
          </Button>
        </RsButtonToolbar>
      </RsFormGroup>
    </FormControlled>
  );
};

export default FormSimple;
