import React from 'react';
import { Button as RsButton, Progress as RsProgress } from 'rsuite';
import classNames from 'classnames';

const Button = ({ isLoading, progress, isDisabled, ...props }) => {
  return (
    <RsButton
      loading={isLoading && (isNaN(progress) || progress >= 100)}
      className={`${props?.className || ''} ${classNames({
        'rs-btn-progressing': isLoading && progress < 100,
      })}`}
      disabled={isDisabled}
      {...props}
    >
      {isLoading && progress < 100 && (
        <RsProgress.Circle
          percent={progress}
          showInfo={false}
          status="active"
        />
      )}
      {props.children}
    </RsButton>
  );
};

export default Button;
