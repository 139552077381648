import { Fragment, useState, useMemo, useCallback } from 'react';
import {
  Drawer as RsDrawer,
  Icon as RsIcon,
  ButtonToolbar as RsButtonToolbar,
  IconButton as RsIconButton,
  Checkbox as RsCheckbox,
  CheckboxGroup as RsCheckboxGroup,
} from 'rsuite';
import useHotkeys from 'react-use-hotkeys';

import { useLogData } from 'app/services/log';
import getRandomString from 'app/services/utils/getRandomString';
import './DebugConsole.scss';

const logIconByDataType = {
  error: 'close-circle',
  warning: 'exclamation-triangle',
  success: 'check-circle',
};

const logTypes = [
  {
    name: 'info',
    title: 'Info',
    default: true,
  },
  {
    name: 'warning',
    title: 'Warnings',
  },
  {
    name: 'error',
    title: 'Errors',
  },
];
const logTypeNames = logTypes.map((logType) => logType.name);
const defaultLogType = logTypes.find((logType) => logType.default);

const DebugConsole = () => {
  const [isShown, setIsShown] = useState();
  const [data, setData] = useState([]);
  const [viewOptionsFiltered, setViewOptionsFiltered] = useState(
    () => logTypeNames,
  );

  const onLogDataUpdate = useCallback((newData) => {
    if (!(newData?.length > 0)) return;

    const newDataAugmented = newData.map((d) => ({
      dateLocaleString: d.createdAt.toLocaleString(undefined, {
        // year: '2-digit',
        // month: '2-digit',
        // day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      }),
      ...d,
      icon: logIconByDataType[d.data.type],
      id: d.createdAt.getTime() + getRandomString(4),
      logType: logTypeNames.includes(d.data.type)
        ? d.data.type
        : defaultLogType.name,
    }));

    setData((previous) => [...newDataAugmented, ...previous]);
  }, []);

  useLogData(onLogDataUpdate);

  const dataFiltered = useMemo(
    () => data.filter((d) => viewOptionsFiltered.includes(d.logType)),
    [data, viewOptionsFiltered],
  );

  useHotkeys('d e b u g', (event) => {
    if (
      event.target?.nodeName.toLowerCase() === 'textarea' ||
      (event.target?.nodeName.toLowerCase() === 'input' &&
        event.target.getAttribute('type') === 'text')
    ) {
      return;
    }

    setIsShown(true);
  });

  useHotkeys('Escape', () => {
    setIsShown(false);
  });

  return (
    <RsDrawer
      backdrop={true}
      className="rs-drawer-semitransparent"
      show={isShown}
      onHide={() => setIsShown(false)}
    >
      <RsDrawer.Header>
        <RsDrawer.Title>Debug Console</RsDrawer.Title>
      </RsDrawer.Header>
      <RsDrawer.Body className="debug-console">
        <div className="debug-console__header">
          <RsCheckboxGroup
            inline
            name="checkboxList"
            value={viewOptionsFiltered}
            onChange={setViewOptionsFiltered}
          >
            {logTypes.map((logType) => (
              <RsCheckbox key={logType.name} value={logType.name}>
                {logType.title}
              </RsCheckbox>
            ))}
          </RsCheckboxGroup>
          <RsButtonToolbar>
            <RsIconButton
              icon={<RsIcon icon="trash-o" />}
              onClick={() => {
                setData([]);
              }}
              size="sm"
            />
          </RsButtonToolbar>
        </div>
        <div className="debug-console__content">
          <div className="debug-console__list">
            {dataFiltered.map((d) => (
              <Fragment key={d.id}>
                <div className={`debug-item__icon debug-item--${d.logType}`}>
                  {d.icon && <RsIcon icon={d.icon} />}
                </div>
                <div className={`debug-item__date debug-item--${d.logType}`}>
                  {d.dateLocaleString}
                </div>
                <div className={`debug-item__message debug-item--${d.logType}`}>
                  {d.data.title && (
                    <div className="debug-item__title">{d.data.title}</div>
                  )}
                  {d.data.description && (
                    <div className="debug-item__description">
                      {d.data.description}
                    </div>
                  )}
                  {d.data.code && (
                    <div className="debug-item__code">{d.data.code}</div>
                  )}
                </div>
              </Fragment>
            ))}
          </div>
        </div>
      </RsDrawer.Body>
    </RsDrawer>
  );
};

export default DebugConsole;
