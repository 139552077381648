import { useEffect, useCallback } from 'react';
import _ from 'lodash';
import { createStore } from 'react-hooks-global-state';
import { usePrevious } from 'react-recipes';

const { useLog, useLogData, setConfig } = makeUseLog();

export { useLog, useLogData, setConfig, makeUseLog };

function makeUseLog(_config = {}) {
  let config = {};

  const reducer = (state, action) => {
    switch (action.type) {
      case 'add':
        const now = new Date();
        return {
          ...state,
          log: [
            ...(_.isArray(action.payload)
              ? action.payload
                  .map((d) => ({
                    createdAt: now,
                    data: d,
                  }))
                  .splice(0, config?.max)
              : [{ createdAt: now, data: action.payload }]),
            ...state.log,
          ].splice(0, config?.max),
        };
      case 'clear':
        return { ...state, log: [] };
      default:
        return state;
    }
  };

  const initialState = { log: [] };
  const { dispatch, useGlobalState } = createStore(reducer, initialState);

  const setConfig = (_config = {}) => {
    config = {
      max: 1000,
      ..._config,
    };
  };

  const useLog = () => {
    const add = useCallback(
      (data) => dispatch({ type: 'add', payload: data }),
      [],
    );

    const clear = useCallback(() => dispatch({ type: 'clear' }), []);

    return {
      add: add,
      clear: clear,
    };
  };

  const useLogData = (onUpdate) => {
    const [data] = useGlobalState('log');
    const prevData = usePrevious(data);

    useEffect(() => {
      if (
        onUpdate instanceof Function &&
        data?.length > 0 &&
        data?.length !== prevData?.length
      ) {
        onUpdate(data.slice(0, data.length - prevData.length));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return {
      data: data,
    };
  };

  setConfig(_config);

  return { useLog: useLog, useLogData: useLogData, setConfig: setConfig };
}
