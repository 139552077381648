const colors = [
  '#252525',
  '#525252',
  '#737373',
  '#969696',
  '#bdbdbd',
  '#d9d9d9',
  '#f0f0f0',
];

const baseLabelStyles = {
  fill: 'inherit',
  fontFamily: 'inherit',
  stroke: 'inherit',
};

export const animation = {
  delay: 0,
  easing: 'cubicInOut',
  duration: 1000,
  onLoad: {
    delay: 0,
    duration: 1,
  },
};

export const theme = {
  axis: {
    style: {
      axis: {
        // if not set undefined explicitely an inline
        // style "stroke: #000" would be set
        // stroke: undefined,
      },
      grid: {
        // if not set undefined explicitely an inline
        // style "stroke: #000" would be set
        stroke: undefined,
      },
      ticks: {
        // if not set undefined explicitely an inline
        // style "stroke: #000" would be set
        stroke: undefined,
        // we need to translate the tick with -1 to overlay
        // the axis completely => size = 5 + 1
        size: 6,
      },
      tickLabels: {
        padding: 10,
        ...baseLabelStyles,
      },
    },
  },
  pie: {
    style: {
      data: {
        stroke: 'transparent',
        strokeWidth: 1,
      },
      labels: {
        ...baseLabelStyles,
        padding: 20,
      },
    },
    colorScale: colors,
  },
};
