import { useEffect, useRef, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import axios from 'axios';

import { Provider as HeaderProvider } from 'app/components/Header/Context';
import Header from 'app/components/Header';
import FlexOverflow from 'app/components/FlexOverflow';
import NotFound from 'app/components/NotFound';
import {
  setConfig as setAxiosConfig,
  applyMiddleware as applyAxiosMiddleware,
} from 'app/services/axios/axios';
import createAxiosNotificationMiddleware from 'app/middleware/axios/notification';
import createAxiosDebugMiddleware from 'app/middleware/axios/debug';
import { useLog, setConfig as setLogConfig } from 'app/services/log';
import {
  augmentError as augmentApiError,
  createLogItem as createApiLogItem,
} from 'app/services/api/api';
import DebugConsole from 'app/components/DebugConsole';
import Domain from 'app/domain';

import 'app/styles/rsuite/index.less';
import './App.scss';

const appName = process.env?.REACT_APP_NAME || '';

const axiosNotificationMiddleware = createAxiosNotificationMiddleware({
  getData: (event) =>
    event.name === 'onError' && augmentApiError(event.payload),
});

setLogConfig({
  max: 500,
});

const Root = () => {
  const { add: addToLog } = useLog();
  const [initialized, setInitialized] = useState(false);

  const axiosDebugMiddlewareRef = useRef(
    createAxiosDebugMiddleware({
      getData: createApiLogItem,
      addDataToLog: addToLog,
    }),
  );

  useEffect(() => {
    setAxiosConfig([
      {
        name: 'api',
        axiosInstance: axios.create({
          baseURL: process.env?.REACT_APP_API_URL || '',
          headers: {
            Accept: 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
          },
        }),
        middleware: applyAxiosMiddleware(
          axiosNotificationMiddleware,
          axiosDebugMiddlewareRef.current,
        ),
      },
      {
        name: 'objectApi',
        axiosInstance: axios.create({
          baseURL: process.env?.REACT_APP_OBJECT_API_URL || '',
          headers: {
            Accept: 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
          },
        }),
        middleware: applyAxiosMiddleware(
          axiosNotificationMiddleware,
          axiosDebugMiddlewareRef.current,
        ),
      },
    ]);

    setInitialized(true);
  }, []);

  return (
    <>
      {initialized && (
        <div className="l-page l-page--full-size l-flex l-flex--column">
          <Header />
          <FlexOverflow className="l-flex__item--main">
            <Switch>
              <Route path="/" exact>
                <Domain.Start parentTitle={appName} />
                {/* <Redirect
                to={{
                  pathname: '/variable/stats',
                  search: window.location.search,
                }}
              /> */}
              </Route>
              <Route path="/legal" exact>
                <Domain.Legal parentTitle={appName} />
              </Route>
              <Route path="/privacy" exact>
                <Domain.PrivacyPolicy parentTitle={appName} />
              </Route>
              <Route path="/variable">
                <Domain.Variable parentTitle={appName} />
              </Route>
              <Route>
                <div className="l-flex__item--main l-flex l-flex--column l-flex--center-center">
                  <NotFound parentTitle={appName} />
                </div>
              </Route>
            </Switch>
          </FlexOverflow>
        </div>
      )}
    </>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <HelmetProvider>
        <HeaderProvider>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{appName}</title>
          </Helmet>
          <DebugConsole />
          <Root />
        </HeaderProvider>
      </HelmetProvider>
    </BrowserRouter>
  );
};

export default App;
