import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import getUrlSearchParams from '../getUrlSearchParams';

const useUrlSearchParams = () => {
  const location = useLocation();

  return useMemo(() => getUrlSearchParams(location.search), [location.search]);
};

export default useUrlSearchParams;
